<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Supplier</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="supplierInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Phone Number</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="form.phone"
                  />
                  <small class="text-danger" v-if="errors.phone">{{
                    errors.phone[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.address"
                  />
                  <small class="text-danger" v-if="errors.address">{{
                    errors.address[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="form-group col-6">
                    <label>Tempo</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.tempo"
                    />
                    <small class="text-danger" v-if="errors.tempo">{{
                      errors.tempo[0]
                    }}</small>
                  </div>
                  <div class="form-group col-6">
                    <label>Status Tempo</label>
                    <model-list-select
                      v-model="form.status_tempo"
                      placeholder="Select Status Tempo"
                      :list="status_tempo"
                      option-value="value"
                      option-text="name"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.status_tempo">{{
                      errors.status_tempo[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "FormSupplier",
  data() {
    return {
      name: "Form Supplier",
      status_tempo: [
        { value: "DAY", name: "DAY" },
        { value: "WEEK", name: "WEEK" },
        { value: "MONTH", name: "MONTH" },
      ],
      form: {
        name: "",
        phone: "",
        address: "",
        tempo: "",
        status_tempo: "",
      },
      isSubmitting: false,
      errors: {},
      edit: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("supplier/" + id)
        .then((result) => {
          this.form.name = result.data.data.name;
          this.form.phone = result.data.data.phone;
          this.form.address = result.data.data.address;
          this.form.tempo = result.data.data.tempo;
          this.form.status_tempo = result.data.data.status_tempo;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    supplierInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "supplier/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your supplier has been created!");
            this.$router.push({ name: "Supplier" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(this.$store.state.api + "supplier/update/" + id, this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your supplier has been updated!");
            this.$router.push({ name: "Supplier" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
