import Supplier from './Supplier.vue'
import SupplierCreate from './Create.vue'
import SupplierEdit from './Create.vue'

export default [
    {
        path: '/supplier',
        name: 'Supplier',
        component: Supplier
    },
    {
        path: '/supplier/create',
        name: 'SupplierCreate',
        component: SupplierCreate
    },
    {
        path: '/supplier/edit/:id',
        name: 'SupplierEdit',
        component: SupplierEdit
    },
]
